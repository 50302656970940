import { Component } from '@angular/core';

@Component({
  selector: 'app-config-component',
  standalone: true,
  imports: [],
  templateUrl: './config.component.html',
  styleUrl: './config.component.css'
})
export class ConfigComponent {

}
