import { Component } from '@angular/core';
import { TranslateService } from '../../services/translate.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTab, MatTabGroup } from "@angular/material/tabs";

//Para cada componente debo usar los estilos

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
  imports: [
    MatButtonModule,
    MatTabGroup,
    MatTab,
    CommonModule
  ],
  standalone: true
})
export class WelcomeComponent {
  result: string | undefined;

  constructor(private translateService: TranslateService) { }

  fetchTranslation() {
    this.translateService.getTranslation().subscribe(
      response => {
        this.result = response.result; // Asegúrate de que el nombre del campo es correcto
      },
      error => {
        console.error('Error fetching translation:', error);
      }
    );
  }
}
