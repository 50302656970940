import { Component } from '@angular/core';

@Component({
  selector: 'app-quiz-component',
  standalone: true,
  imports: [],
  templateUrl: './quiz.component.html',
  styleUrl: './quiz.component.css'
})
export class QuizComponent {

}
