import { Component, OnInit } from '@angular/core';
import { MatTab, MatTabGroup } from "@angular/material/tabs";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-menu-component',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  selectedIndex: number = 0;
  readonly routes: string[] = ['/home', '/audio', '/quiz', '/home'];

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.selectedIndex = this.routes.indexOf(this.router.url);
    });

    // Inicializar el índice seleccionado al cargar la página
    this.selectedIndex = this.routes.indexOf(this.router.url);
  }

  onTabChange(event: any) {
    this.router.navigate([this.routes[event.index]]);
  }
}
