import { Routes } from '@angular/router';
import { WelcomeComponent } from './components/welcome-message/welcome.component';
import {AudioComponent} from "./components/audio-component/audio.component";
import {QuizComponent} from "./components/quiz-component/quiz.component";
import {ConfigComponent} from "./components/config-component/config.component";

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: WelcomeComponent },
  { path: 'audio', component: AudioComponent },
  { path: 'quiz', component: QuizComponent },
  { path: 'config', component: ConfigComponent }
];
